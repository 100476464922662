<template>
  <div class="Agreement">
    <div class="agreement_block">万鲸服务条款</div>
    <p>版本号：2023年第1版</p>
    <p>
      <br />
    </p>
    <p>最新修订日期：20230130</p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>
      欢迎“您”（以下或称“用户”）与“深圳市万鲸科技有限公司”（下称“万鲸”）共同签署本《万鲸服务条款》协议（下称“本协议”），本协议阐述之条款和条件适用于您使用万鲸基于互联网，以包含万鲸网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。&nbsp;
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>
      本协议为《万鲸服务条款》修订版本，自本协议发布之日起，万鲸平台各处所称“万鲸服务条款”均指本协议。
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>一、接受协议</p>
    <p>
      <br />
    </p>
    <p>
      1.1
      本协议内容包括协议正文及所有万鲸已经发布或将来可能发布并采取合理途径通知的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。
    </p>
    <p>
      <br />
    </p>
    <p>
      1.2您在点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可登录万鲸服务中心向万鲸客服咨询。
    </p>
    <p>
      <br />
    </p>
    <p>
      1.3当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序，或以任何方式进入万鲸平台并使用服务即表示您已充分阅读、理解并同意接受本协议的条款和条件。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序并停止使用服务。
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>二、定义</p>
    <p>
      <br />
    </p>
    <p>
      2.1万鲸平台：指万鲸主办运营的面向企业贸易的在线信息发布及交易平台，包含万鲸网站（所涉域名为wanjingai.com，wanjingsc.com下同）及客户端。
    </p>
    <p>
      <br />
    </p>
    <p>2.2万鲸：指“深圳市万鲸科技有限公司”，系万鲸平台的经营者。</p>
    <p>
      <br />
    </p>
    <p>
      2.3万鲸服务：指万鲸基于互联网，以包含万鲸网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务（下称“服务”）。
    </p>
    <p>
      <br />
    </p>
    <p>
      2.4万鲸平台规则：指在万鲸平台（域名为wanjingai.com，wanjingsc.com）内已发布及后续发布的全部规则、解读、公告等内容以及万鲸在论坛、帮助中心等渠道发布的各类规则、实施细则、产品流程说明、公告等。
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>三、协议范围</p>
    <p>
      <br />
    </p>
    <p>3.1签约主体</p>
    <p>
      <br />
    </p>
    <p>本协议由您与万鲸共同缔结，本协议对您与万鲸具有同等合同效力。</p>
    <p>
      <br />
    </p>
    <p>3.2补充协议</p>
    <p>
      <br />
    </p>
    <p>
      由于互联网高速发展，您与万鲸签署的本协议列明的条款并不能完整罗列并覆盖您与万鲸所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，万鲸法律声明、隐私政策、万鲸平台规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用万鲸服务，视为您同意上述补充协议。
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>四、账户注册与使用</p>
    <p>
      <br />
    </p>
    <p>4.1用户资格</p>
    <p>
      <br />
    </p>
    <p>
      4.1.1您确认，在您完成注册程序或以其他万鲸允许的方式实际使用服务时，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的相应后果，且万鲸有权终止向您提供服务并注销您的账户。如您代表一家公司或其他法律主体在万鲸平台注册，则您声明和保证，您有权使该公司或该法律主体受本协议的约束。&nbsp;
    </p>
    <p>
      <br />
    </p>
    <p>
      4.1.2此外，您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用服务。
    </p>
    <p>
      <br />
    </p>
    <p>4.2账户注册</p>
    <p>
      <br />
    </p>
    <p>
      4.2.1在您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或在您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他万鲸允许的方式实际使用万鲸服务时，您即受本协议的约束。您可以使用您提供或确认的邮箱、手机号码或者万鲸允许的其它方式作为登录手段进入万鲸平台。
    </p>
    <p>
      <br />
    </p>
    <p>
      4.2.2您了解并同意，如您系在万鲸平台完成的注册程序，只要您注册成功，您即可以获得您的手机号码登录名（以下或称“账户”）。您可以通过您的手机号码登录名直接登录万鲸平台，无需另行设置账户。
    </p>
    <p>
      <br />
    </p>
    <p>
      4.2.3由于您的账户关联您的信息及万鲸平台商业信息，您的账户仅限您本人使用。未经万鲸同意，您直接或间接授权第三方使用您账户或获取您账户项下信息的行为无效。如万鲸根据您账户的使用行为等情况判断您账户的使用可能危及您的账户安全及/或万鲸平台信息安全的，万鲸可拒绝提供相应服务或终止本协议。
    </p>
    <p>
      <br />
    </p>
    <p>4.3账户转让</p>
    <p>
      <br />
    </p>
    <p>
      由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经万鲸同意，并符合万鲸平台规则规定的账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则万鲸有权追究您的违约责任，且由此产生的相应责任均由您承担。
    </p>
    <p>
      <br />
    </p>
    <p>4.4实名认证</p>
    <p>
      <br />
    </p>
    <p>
      为使您更好地使用万鲸平台的各项服务，保障您的账户安全，万鲸可要求您按国家法律法规的规定完成实名认证。
    </p>
    <p>
      <br />
    </p>
    <p>4.5不活跃账户回收</p>
    <p>
      <br />
    </p>
    <p>
      如您的账户同时符合以下条件，您的登录名可能被注销，不能再登陆任一万鲸平台，所有万鲸平台服务将同时终止：
    </p>
    <p>
      <br />
    </p>
    <p>（一）未按照国家相关法律法规以及万鲸要求通过实名认证；</p>
    <p>
      <br />
    </p>
    <p>
      （二）连续6个月未使用您的邮箱、手机或万鲸认可的其他方式和密码登录过万鲸平台，也未登录过其他任一万鲸平台；
    </p>
    <p>
      <br />
    </p>
    <p>（三）不存在未到期的有效业务。</p>
    <p>
      <br />
    </p>
    <p>4.6注册信息管理</p>
    <p>
      <br />
    </p>
    <p>
      4.6.1在完成注册或激活流程时，您应当按万鲸平台相应页面的提示准确完整地提供并及时更新您的信息，以使之始终保持真实、及时，完整和准确。在国家法律法规有明确规定要求万鲸作为平台服务提供者必须对用户（如卖家等）的信息进行核实的情况下，万鲸将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。
    </p>
    <p>
      <br />
    </p>
    <p>
      4.6.2如万鲸按您最后一次提供的信息与您联系未果、您未按万鲸的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及万鲸造成的全部损失与不利后果。万鲸可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部万鲸服务，除法律明确规定外万鲸对此不承担责任。
    </p>
    <p>
      <br />
    </p>
    <p>
      4.6.3您设置的账户登录名及昵称不得违反国家法律法规及万鲸平台规则的相应管理规定，否则万鲸可回收您的登录名及昵称，并按万鲸平台规则进行相应的处理。
    </p>
    <p>
      <br />
    </p>
    <p>4.7账户安全&nbsp;</p>
    <p>
      <br />
    </p>
    <p>
      4.7.1您的账户为您自行设置并由您保管，万鲸任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开万鲸平台。账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，万鲸并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。
    </p>
    <p>
      <br />
    </p>
    <p>
      4.7.2除万鲸存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、询/报价、采购产品、订购服务及披露信息等）负责。
    </p>
    <p>
      <br />
    </p>
    <p>
      4.7.3如发现任何未经授权使用您账户登录万鲸平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知万鲸。您理解万鲸对您的任何请求采取行动均需要合理时间，且万鲸应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除万鲸存在法定过错外，万鲸不承担责任。
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>五、服务及规范</p>
    <p>
      <br />
    </p>
    <p>
      您有权在万鲸平台上享受账户管理、产品及/或服务的报价、销售与推广、产品及/或服务的询价、采购与评价、交易争议处理等服务。万鲸提供的服务内容众多，具体您可登录万鲸平台浏览。
    </p>
    <p>
      <br />
    </p>
    <p>5.1账户管理</p>
    <p>
      <br />
    </p>
    <p>
      5.1.1通过在万鲸平台创建账户，您可发布各类产品及/或服务信息，并根据订单达成交易。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.1.2账号转让实质为经营者账户的转让，账号转让的相关要求与限制请适用本协议4.3账户转让条款。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.1.3如您通过下架全部产品进行短暂关停，您仍应当对关停前已达成的交易继续承担发货、纠纷及投诉处理等交易保障责任。
    </p>
    <p>
      <br />
    </p>
    <p>5.2产品及/或服务的报价、销售与推广</p>
    <p>
      <br />
    </p>
    <p>
      5.2.1通过万鲸提供的服务，您有权通过文字、图片等形式在万鲸平台上发布产品及/或服务信息、进行报价、达成交易。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.2.2您应当确保您对您在万鲸平台上发布的产品及/或服务享有相应的权利，您不得在万鲸平台上销售以下产品及/或提供以下服务：
    </p>
    <p>
      <br />
    </p>
    <p>（一）国家禁止或限制的；</p>
    <p>
      <br />
    </p>
    <p>（二）侵犯他人知识产权或其它合法权益的；</p>
    <p>
      <br />
    </p>
    <p>
      （三）万鲸平台规则、公告、通知或各平台与您单独签署的协议中已明确说明不适合在万鲸平台上销售及/或提供的。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.2.3您应当遵守诚实信用原则，确保您所发布的产品及/或服务信息真实、与您实际所销售的产品及/或提供的服务相符，并在交易过程中切实履行您的交易承诺。您应当维护万鲸平台良性的市场竞争秩序，不得贬低、诋毁竞争对手，不得干扰万鲸平台上进行的任何交易、活动，不得以任何方式干扰或试图干扰万鲸平台的正常运作。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.2.4您有权自行决定产品及/或服务的促销及推广方式，万鲸亦为您提供了形式丰富的促销推广工具。您的促销推广行为应当符合国家相关法律法规及万鲸平台的要求。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.2.5依法纳税是每一个公民、企业应尽的义务，您应对销售额/营业额超过法定免征额部分及时、足额地向税务主管机关申报纳税。
    </p>
    <p>
      <br />
    </p>
    <p>5.3产品及/或服务的询价、采购与评价</p>
    <p>
      <br />
    </p>
    <p>
      5.3.1当您在万鲸平台询价、采购产品及/或服务时，请您务必仔细确认采购产品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在采购下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.3.2您充分了解并同意，万鲸平台是一个商业贸易采购平台，而并非面向消费者的消费购买市场，故您的询价、采购行为应当基于真实的贸易需求，不得存在对产品及/或服务实施恶意询价、采购、恶意维权等扰乱万鲸平台正常交易秩序的行为。基于维护万鲸平台交易秩序及交易安全的需要，万鲸发现上述情形时可主动执行关闭相关交易订单等操作。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.3.3您有权在万鲸平台提供的评价系统中对与您达成交易的其他用户产品及/或服务进行评价。您的所有评价行为应遵守万鲸平台规则的相关规定，评价内容应当客观真实，不应包含任何污言秽语、色情低俗、广告信息及法律法规与本协议列明的其他禁止性信息；您不应以不正当方式帮助他人提升信用或利用评价权利对其他用户实施威胁、敲诈勒索。万鲸可按照万鲸平台规则的相关规定对您实施上述行为所产生的评价信息进行删除或屏蔽。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.3.4
      如您在万鲸平台询价、采购的产品及/或服务为用于对外出口的商品及/或服务：
    </p>
    <p>
      <br />
    </p>
    <p>
      5.3.4.1 您承诺遵守所有适用的进出口管制、贸易限制与经济制裁相关法律法规。
      如您违反前述承诺的，万鲸可在发现上述情形时停止提供相关服务。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.3.4.2您需确保您不是任何国家、地区或国际组织实施的贸易限制、经济制裁或其他法律法规限制的对象，也未直接或间接为前述对象提供资金、商品或服务，否则您应当停止使用万鲸提供的服务，同时您理解违反前述要求可能会造成您无法正常注册及使用万鲸服务。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.3.5
      针对通过万鲸平台取得的商品、服务、软件及技术，您承诺遵守所有适用的进出口管制、贸易限制与经济制裁相关法律法规，包括联合国安全理事会、中国、美国及任何其他国家制定并执行的制裁决议、法律法规。基于维护交易秩序及交易安全的需要，如您违反前述承诺的，万鲸可在发现上述情形时终止服务及主动执行关闭相关交易订单及作出账户处置等操作。
    </p>
    <p>
      <br />
    </p>
    <p>5.4交易争议处理</p>
    <p>
      <br />
    </p>
    <p>
      5.4.1 您可通过万鲸平台所支持的交易方式达成交易，并遵守相应交易与支付规则。
    </p>
    <p>
      <br />
    </p>
    <p>
      5.4.2您在万鲸平台交易过程中发生争议的，您或其他用户中任何一方均有权选择以下途径解决：
    </p>
    <p>
      <br />
    </p>
    <p>（一）与争议相对方自主协商；</p>
    <p>
      <br />
    </p>
    <p>（二）向有关行政部门投诉；</p>
    <p>
      <br />
    </p>
    <p>（三）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；</p>
    <p>
      <br />
    </p>
    <p>（四）向人民法院提起诉讼。</p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>六、费用</p>
    <p>
      <br />
    </p>
    <p>
      6.1万鲸为万鲸平台向您提供的服务付出了大量的成本，除万鲸平台明示的收费业务外，万鲸向您提供的服务目前是免费的。如未来万鲸向您收取合理费用，万鲸会采取合理途径并提前通过法定程序以本协议约定的有效方式通知您，确保您有充分选择的权利。
    </p>
    <p>
      <br />
    </p>
    <p>
      6.2您因进行交易、向万鲸获取有偿服务或接触万鲸服务器而发生的所有应纳税赋，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担。
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>七、责任限制</p>
    <p>
      <br />
    </p>
    <p>
      7.1万鲸依照法律规定履行基础保障义务，但对于下述不可抗力或其他第三人因素原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，万鲸并不承担相应的违约责任：
    </p>
    <p>
      <br />
    </p>
    <p>
      （一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
    </p>
    <p>
      <br />
    </p>
    <p>（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</p>
    <p>
      <br />
    </p>
    <p>
      （三）在万鲸已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。
    </p>
    <p>
      <br />
    </p>
    <p>
      7.2万鲸仅向您提供本协议约定之服务，您了解万鲸平台上的信息系用户自行发布，且可能存在风险和瑕疵。万鲸将通过依照法律规定建立相关检查监控制度尽可能保障您在万鲸平台的合法权益及良好体验。同时，鉴于万鲸平台具备存在海量信息及信息网络环境下信息与实物相分离的特点，万鲸作为网络交易平台无法逐一审查产品及/或服务的信息，无法逐一审查交易所涉及的产品及/或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断，如发现存在危害信息和行为，请您及时同万鲸进行联系和投诉，万鲸将对有害信息和行为进行处理。
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>八、用户信息的保护、授权及信息发布规范</p>
    <p>
      <br />
    </p>
    <p>8.1个人信息的保护</p>
    <p>
      <br />
    </p>
    <p>
      万鲸非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用万鲸提供的服务时，您同意万鲸按照在万鲸平台上公布的隐私政策收集、存储、使用、披露和保护您的个人信息。万鲸希望通过隐私政策向您清楚地介绍万鲸对您个人信息的处理方式，因此万鲸建议您完整地阅读隐私政策（点击此处或点击万鲸平台首页底部链接），以帮助您更好地保护您的隐私权。
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>8.2信息发布规范与非个人信息的授权</p>
    <p>
      <br />
    </p>
    <p>
      8.2.1您必须为自己注册帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。您在使用本协议项下服务时须遵守法律法规，不得制作、复制、发布、传播、评论含有下列内容的信息或从事相关行为，也不得为制作、复制、发布、传播、评论含有下列内容的信息或从事相关行为提供便利：
    </p>
    <p>
      <br />
    </p>
    <p>（一）违反国家法律法规禁止性规定的；</p>
    <p>
      <br />
    </p>
    <p>（二）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>
      <br />
    </p>
    <p>（三）损害国家荣誉和利益的；</p>
    <p>
      <br />
    </p>
    <p>（四）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p>
      <br />
    </p>
    <p>（五）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p>
      <br />
    </p>
    <p>（六）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p>
      <br />
    </p>
    <p>（七）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
    <p>
      <br />
    </p>
    <p>（八）欺诈、虚假、不准确或存在误导性的；</p>
    <p>
      <br />
    </p>
    <p>（九）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</p>
    <p>
      <br />
    </p>
    <p>（十）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；</p>
    <p>
      <br />
    </p>
    <p>
      （十一）存在可能破坏、篡改、删除、影响万鲸平台任何系统正常运行或未经授权秘密获取万鲸平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
    </p>
    <p>
      <br />
    </p>
    <p>
      （十二）违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的；
    </p>
    <p>
      <br />
    </p>
    <p>
      （十三）其他违背社会公共利益或公共道德或法律规定或依据相关万鲸平台协议、规则的规定不适合在万鲸平台上发布的。
    </p>
    <p>
      <br />
    </p>
    <p>
      8.2.2
      您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，万鲸可对您发布的信息依法或依本协议进行删除或屏蔽。
    </p>
    <p>
      <br />
    </p>
    <p>
      8.2.3
      对于您提供、发布及在使用万鲸服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，均不会因您的上传、发布等行为发生知识产权、肖像权等权利的转移。除非万鲸另行明确说明，在法律允许的范围和法律规定的保护期限内，您免费授予万鲸及其关联公司获得非排他的、无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）权利及可再授权给其他第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。
    </p>
    <p>
      <br />
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>九、用户的违约及处理</p>
    <p>
      <br />
    </p>
    <p>9.1违约认定</p>
    <p>
      <br />
    </p>
    <p>发生如下情形之一的，视为您违约：</p>
    <p>
      <br />
    </p>
    <p>（一）使用万鲸服务时违反有关法律法规规定的；</p>
    <p>
      <br />
    </p>
    <p>（二）违反本协议或本协议补充协议约定的。</p>
    <p>
      <br />
    </p>
    <p>
      为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，万鲸可在万鲸平台规则中约定违约认定的程序和标准。如：万鲸可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。
    </p>
    <p>
      <br />
    </p>
    <p>9.2违约处理措施</p>
    <p>
      <br />
    </p>
    <p>
      9.2.1您在万鲸平台上发布的信息构成违约的，万鲸有权进行独立判断并可根据相应规则立即对相应信息进行删除、屏蔽处理或对您的产品进行下架、删除、监管，并且万鲸有权视具体情况中止或终止提供本协议的服务。
    </p>
    <p>
      <br />
    </p>
    <p>
      9.2.2您在万鲸平台上实施的行为，或虽未在万鲸平台上实施但对万鲸平台及其用户产生影响的行为构成违约的，万鲸可依据相应规则对您执行账户限制、中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，万鲸可关闭您的账户，终止向您提供服务。
    </p>
    <p>
      <br />
    </p>
    <p>
      9.2.3当您违约的同时存在欺诈、售假、盗用他人账户等特定情形或您存在危及他人交易安全或账户安全风险时，万鲸会依照您行为的风险程度对您的（及您账户绑定的，下同）账户采取取消收款、资金止付等强制措施。
    </p>
    <p>
      <br />
    </p>
    <p>
      9.2.4万鲸可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在万鲸平台上予以公示。
    </p>
    <p>
      <br />
    </p>
    <p>9.3赔偿责任</p>
    <p>
      <br />
    </p>
    <p>
      9.3.1如您的行为使万鲸及/或其关联公司遭受损失，您应根据本协议履行赔偿责任，您需赔偿万鲸及/或其关联公司的因此遭受的损失（如有）包括：
    </p>
    <p>
      <br />
    </p>
    <p>（一）支付的合理律师费、诉讼费、消除影响所支出的必要费用；</p>
    <p>
      <br />
    </p>
    <p>
      （二）因行政处罚、司法裁判、法定标准范围内的调解等对外支出的罚款、违约金或赔偿金；
    </p>
    <p>
      <br />
    </p>
    <p>
      （三）商誉损失、合理预期利益损失（如会员的流失、支出减少、消费频次降低及店铺经营者收入减少等），具体金额可以第三方独立合理分析、万鲸根据大数据合理分析及其他计算收益、损失的合理方法所得出的相关估算标准作为计算依据；
    </p>
    <p>
      <br />
    </p>
    <p>（四）因此遭受的其他损失。</p>
    <p>
      <br />
    </p>
    <p>
      9.3.2如您的行为使万鲸及/或其关联公司遭受第三人主张权利，万鲸及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。
    </p>
    <p>
      <br />
    </p>
    <p>
      9.3.3如因您的行为使得第三人遭受损失或您怠于履行调处决定，您授权并同意万鲸及/或其关联公司出于保护社会公共利益或保护其他用户合法权益目的，自您的应付款中划扣相应款项进行支付。如您的应付款余额或保证金不足以支付相应款项的，您同意委托万鲸使用自有资金代您支付上述款项（但万鲸并无此义务），您应当返还该部分费用并赔偿因此造成万鲸的全部损失。
    </p>
    <p>
      <br />
    </p>
    <p>
      9.3.4
      万鲸因故意或重大过失违约导致您利益受损的，万鲸将以您的直接实际损失为计算依据向您履行赔偿责任。
    </p>
    <p>
      <br />
    </p>
    <p>9.4特别约定</p>
    <p>
      <br />
    </p>
    <p>
      9.4.1如您向万鲸及/或其关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，万鲸可按照万鲸平台规则进行相应处理，并经提前通知后终止与您的所有合作并向您收取违约金及/或赔偿金，该等金额以万鲸因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。
    </p>
    <p>
      <br />
    </p>
    <p>
      9.4.2如您因严重违约导致万鲸终止本协议时，出于维护平台秩序及保护其他用户合法权益的目的，万鲸及/或其关联公司可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议约定的有效方式通知您。
    </p>
    <p>
      <br />
    </p>
    <p>
      9.4.3如万鲸与您签署的其他协议及万鲸及/或其关联公司与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则万鲸出于维护平台秩序及保护其他用户合法权益的目的，可在收到指令时中止甚至终止协议，并以本协议约定的有效方式通知您。
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>十、协议的变更</p>
    <p>
      <br />
    </p>
    <p>
      10.1万鲸可根据国家法律法规变化及维护市场秩序、保护用户合法权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议约定的有效通知方式通知您。
    </p>
    <p>
      <br />
    </p>
    <p>
      10.2如您不同意变更事项，您有权于变更事项确定的生效日前联系万鲸反馈意见。如反馈意见得以采纳，万鲸将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用万鲸服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用万鲸服务，则视为您同意已生效的变更事项。
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>十一、有效通知</p>
    <p>
      <br />
    </p>
    <p>11.1有效联系方式</p>
    <p>
      <br />
    </p>
    <p>
      您在注册成为万鲸平台用户，并接受万鲸服务时，您应该向万鲸提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被有效联系的状态。
    </p>
    <p>
      <br />
    </p>
    <p>
      您在注册万鲸平台用户时生成的用于登陆万鲸平台接收站内信、系统消息的会员账户（包括子账户），也作为您的有效联系方式。
    </p>
    <p>
      <br />
    </p>
    <p>
      万鲸将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。
    </p>
    <p>
      <br />
    </p>
    <p>
      您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的产品/服务广告信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权通过万鲸提供的相应的退订功能进行退订。
    </p>
    <p>
      <br />
    </p>
    <p>11.2通知的送达</p>
    <p>
      <br />
    </p>
    <p>
      11.2.1万鲸通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在万鲸平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账户发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
    </p>
    <p>
      <br />
    </p>
    <p>
      11.2.2对于在万鲸平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在万鲸平台注册、更新时提供的手机号码、电子邮箱联系方式以及在注册万鲸用户时生成的账号，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。
    </p>
    <p>
      <br />
    </p>
    <p>
      您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。
    </p>
    <p>
      <br />
    </p>
    <p>
      您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。
    </p>
    <p>
      <br />
    </p>
    <p>
      你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>十二、协议终止</p>
    <p>
      <br />
    </p>
    <p>12.1协议终止的情形</p>
    <p>
      <br />
    </p>
    <p>12.1.1您有权通过以下任一方式终止本协议：</p>
    <p>
      <br />
    </p>
    <p>
      （一）在满足万鲸平台要求的账户注销条件时您通过联系万鲸客服注销您的账户的；
    </p>
    <p>
      <br />
    </p>
    <p>（二）变更事项生效前您停止使用万鲸服务并明示不愿接受变更事项的；</p>
    <p>
      <br />
    </p>
    <p>（三）您明示不愿继续使用万鲸服务，且符合万鲸平台要求的终止条件的。</p>
    <p>
      <br />
    </p>
    <p>12.1.2出现以下情况时，万鲸可以本协议约定的有效方式通知您终止本协议：</p>
    <p>
      <br />
    </p>
    <p>（一）您的实名认证身份无效、不再合法存续或无法有效核实；</p>
    <p>
      <br />
    </p>
    <p>（二）您违反本协议约定，万鲸依据违约条款终止本协议的；</p>
    <p>
      <br />
    </p>
    <p>
      （三）您盗用他人账户、发布违禁信息、欺诈、售假、扰乱市场秩序、采取不正当手段牟利等行为，万鲸依据万鲸平台规则对您的账户予以关闭的；
    </p>
    <p>
      <br />
    </p>
    <p>
      （四）除上述情形外，因您多次违反万鲸平台规则相关规定且情节严重，万鲸依据万鲸平台规则对您的账户予以关闭的；
    </p>
    <p>
      <br />
    </p>
    <p>（五）您的账户被万鲸依据本协议回收的；</p>
    <p>
      <br />
    </p>
    <p>
      （六）您有欺诈、发布或销售假冒伪劣/侵权产品、侵犯他人合法权益或其他严重违法违约行为的；
    </p>
    <p>
      <br />
    </p>
    <p>
      （七）万鲸基于合理理由相信您的行为可能会使您、万鲸及万鲸平台用户等相关方发生严重损害或产生法律责任；
    </p>
    <p>
      <br />
    </p>
    <p>（八）其它应当终止服务的情况。</p>
    <p>
      <br />
    </p>
    <p>12.2协议终止后的处理</p>
    <p>
      <br />
    </p>
    <p>
      12.2.1本协议终止后，除法律有明确规定外，万鲸无义务向您或您指定的第三方披露您账户中的任何信息。
    </p>
    <p>
      <br />
    </p>
    <p>12.2.2本协议终止后，万鲸仍享有下列权利：</p>
    <p>
      <br />
    </p>
    <p>（一）继续保存您留存于万鲸平台的本协议第八条所列的各类信息；</p>
    <p>
      <br />
    </p>
    <p>（二）对于您过往的违约行为，万鲸仍可依据本协议向您追究违约责任。</p>
    <p>
      <br />
    </p>
    <p>
      12.2.3本协议终止后，对于您在本协议存续期间产生的交易订单，万鲸可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。
    </p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>&nbsp;</p>
    <p>
      <br />
    </p>
    <p>十三、法律适用、管辖及其他</p>
    <p>
      <br />
    </p>
    <p>
      13.1本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律；如无相关法律规定的，则应参照商业惯例和（或）行业惯例。&nbsp;
    </p>
    <p>
      <br />
    </p>
    <p>
      13.2您与万鲸仅为独立订约人关系。本协议无意结成或创设任何代理、合伙、合营、雇佣与被雇佣或特性授权与被授权关系。&nbsp;
    </p>
    <p>
      <br />
    </p>
    <p>
      13.3除双方另有约定外，您同意万鲸因经营业务需要有权将本协议项下的权利义务就部分或全部进行转让，并采取合理途径提前通过法定程序以本协议约定的有效方式通知您。&nbsp;
    </p>
    <p>
      <br />
    </p>
    <p>
      13.4因本协议或万鲸服务所引起或与其有关的任何争议，由万鲸与您协商解决。协商不成时，任何一方均可向被告住所地人民法院提起诉讼。
    </p>
    <p>
      <br />
    </p>
    <p>
      13.5倘若本协议任何条款被裁定为无效或不可强制执行，该项条款应被撤销，而其余条款应予遵守和执行。条款标题仅为方便参阅而设，并不以任何方式界定、限制、解释或描述该条款的范围或限度。万鲸未就您或其他人士的某项违约行为采取行动，并不表明万鲸撤回就任何继后或类似的违约事件采取行动的权利。
    </p>
    <p>
      <br />
    </p>
    <p>
      13.6万鲸将严格遵守自愿、平等、公平、诚信的合作原则，充分尊重您的自主经营权利。如您发现万鲸员工存在违反上述合作原则的不当行为，可以通过监督邮箱【leon@wonwhale.com】反映问题，万鲸将遵循保密、客观的原则开展调查和处理。
    </p>
    <p>
      <br />
    </p>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/27 15:42
 * @version 1.0
 * @description
 */
export default {
  name: "Agreement",
};
</script>

<style lang="scss" scoped>
.Agreement {
  width: 1280px;
  margin: 0 auto;
  padding: 72px 0 60px;

  .agreement_nav {
    width: 1280px;
    margin: 0 auto;
  }

  .agreement_block {
    font-size: 28px;
    font-weight: 700;
    color: #333;
    margin-bottom: 92px;
    text-align: center;
  }

  .agreement_content {
    padding: 0 80px;
    font-size: 18px;
    color: #333;
    line-height: 27px;
    white-space: pre-wrap;
  }
}
</style>
